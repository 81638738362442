import { createApp } from 'vue'
import App from './App.vue'
import router from './router';


import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out 
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';*/
import '@mdi/font/css/materialdesignicons.css';
/* Theme variables */
import './theme/variables.css';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from "vuetify/iconsets/mdi-svg"

import vue3GoogleLogin from 'vue3-google-login'



//PWA
import './registerServiceWorker';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi
    },
  },
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(vuetify)
  .use(vue3GoogleLogin, {
    clientId: '763370656412-hed23gv2ncpmbgaatpald3ur9ve6ks72.apps.googleusercontent.com'
  });
  
router.isReady().then(() => {
  app.mount('#app');
});